import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import AdobeCommerceMagento from "../components/Platforms/AdobeCommerceMagento/AdobeCommerceMagento";
import OpenSource from "../components/Platforms/AdobeCommercemagentoOpensource/AdobeCommercemagentoOpensource";
import MagentoAdobe from "../components/Platforms/MagentoAdobe/MagentoAdobe";
import AdobeCommerce from "../components/Platforms/Adobe-Commerce/AdobeCommerce";
import Comparision from "../components/Platforms/MagentoOpenSourceAdobeCommcerce/MagentoOpenSourceAdobeCommcerce";
// import styles from "./ux-design.module.scss";
import { Helmet } from "react-helmet";

function adobecommercemagento() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout>
       <Helmet>
		 <meta charSet="utf-8" />
        <title>
		Hashout Technologies - Adobe Commerce and Magento
        </title>
        <meta
          name="description"
          content="Combine Adobe Commerce with Adobe’s Enterprise-grade analytics, personalization, and AI to build the world's most powerful eCommerce experience."
        ></meta></Helmet>
      <SolutionsHelmet />
      <AdobeCommerceMagento />
      <OpenSource />
      <AdobeCommerce />
      <MagentoAdobe />
      <Comparision />
      <LearnAboutUs page="adobe-commerce-magento"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default adobecommercemagento;
