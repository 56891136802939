import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function AdobeCommerceMagento() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform">
              Adobe Commerce and Magento{" "}
            </h1>
            <img
              className="container__logo"
              src="../../solutions/platforms/solution-partner.png"
              alt="Adobe Experience Manager"
            />
            <h2 className="container__sub-heading container__sub-heading--platform">
              Combine Adobe Commerce with Adobe’s Enterprise-grade analytics,
              personalization, and AI to build the world's most powerful
              eCommerce experience.{" "}
            </h2>
            <h6 className="container__content">
              Create a powerful eCommerce experience for your brand and go to
              market fast with Hashout’s expert Commerce team who will help you
              right from the strategy to delivery and support.
            </h6>
            <a
              className="container__link"
              href="/blog/Magento-Vs-Shopify"
              target="_blank"
            >
              commerce vs shopify
            </a>
            <button
              className="button container__button container__button--platform button--orange"
              onClick={openModal}
            >
              GET A QUOTE
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/adobe-commerce-magento.png"
              alt="Adobe Commerce and Magento"
            />
          </div>
        </div>
      </div>{" "}
      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Adobe-Commerce-magento-Banner"
        page="Adobe-Commerce-magento"
       
      />
    </section>
  );
}

export default AdobeCommerceMagento;
