import React from "react";

function OpenSource() {
  return (
    <section className="section section--services section--wave-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center --section-small-tb-padding">
          <div className="container__col-md-5 container__col-xs-12 --section-normal-tb-padding">
            <h2 className="container__heading">
              Our Adobe Commerce and Magento Open source Services
            </h2>
            <div className="container__row">
              <div className="container__col-md-10">
                <div className="container__services">
                  <div className="container__row">
                    <div className="container__col-md-2 container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10 container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Strategy and planning for both new and existing
                        eCommerce sites.
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Re-branding / UX of existing Magento sites
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Customization using thousands of extensions and themes
                        in the marketplace
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Magento 2.0 Development{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Migration to Adobe Commerce from Open Source Magento
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Upgradation of Magento from 1.0 to 2.0
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container__col-md-6 container__col-xs-12 container__col-md-offset-1 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/adobe-commerce-magento-services.png"
              alt="Adobe Commerce and Magento Services"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OpenSource;
