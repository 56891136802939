import React from "react";

function Comparision() {
  return (
    <section className="section section--img-absolute --section-medium-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <a
              href="/blog/Magento-Opensource-Vs-Adobe-Commerce"
              target="_blank"
            >
              {" "}
              <img
                className="container__image"
                src="../../solutions/platforms/opensource-commerce.png"
                alt="HASHOUT"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Comparision;
