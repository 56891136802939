import React from "react";

function MagentoAdobe() {
  return (
    <section className="section section--tools section--plan section--img-absolute-padding section--bg-lightest-gray --section-medium-tb-padding --section-MagentoAdobe-bottom-pad">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 container__col-md-offset-3 container__col-xs-12">
            <h2 className="container__heading">Magento & Adobe Commcerce Cloud</h2>
            <p className="container__content">
              Self-hosted and fully customizable Commerce solution to power your
              business to build a unique online store from the ground up
            </p>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/magento.png"
                alt="Data Drive"
              />
              <div className="container container--services">
                <div className="container__row">
                  <div className="container__col-md-2 container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10 container__col-xs-10">
                    <h5 className="container__sub-heading">Catalog Management</h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Mobile Optimised Shopping
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">Site Search</h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Integrated Checkout, Payment, Shipping
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">Global Selling</h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      App Marketplace Extensions
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container__col-md-4 container__col-md-offset-1 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/adobe-commerce-cloud.png"
                alt="Cross-Channel"
              />
              <div className="container container--services">
                <div className="container__row">
                  <div className="container__col-md-2 container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10 container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Adobe Commerce Cloud Hosting (SLA 99.99%, CND, Firewall,
                      WAF, DDoS. etc)
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">WYSWIG</h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Content Staging and Preview
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Advance Marketing Tools
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Product Recommendations
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Customer Segmentation
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">
                      Business Intelligence Report
                    </h5>
                  </div>
                </div>
                <div className="container__row">
                  <div className="container__col-md-2  container__col-xs-2">
                    <img
                      src="../../solutions/checkmark-small.png"
                      alt="Hashout"
                    />
                  </div>
                  <div className="container__col-md-10  container__col-xs-10">
                    <h5 className="container__sub-heading">B2B Functionality</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MagentoAdobe;
